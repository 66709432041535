<template>
<v-col>

    <s-crud ref="postionperson" noFull add edit height="auto" @addEvent="add()" :filter="filter" remove title="Cargo por Persona" @save=save($event) @rowSelected="rowSelected($event)" :config="config">
        <!--  <template v-slot:filter>
            <v-col>
                <v-row justify="center">
                    <v-col lg="3">
                        <s-select-definition clearable v-model="filter.TypeArea" :def="1147" label="Tipo de Área"> </s-select-definition>
                    </v-col>
                </v-row>
            </v-col>
        </template> -->

        <template v-slot:default="{ item }">
            <v-container>
                <v-row>
                    <v-col cols="5" class="s-col-form">
                        <s-toolbar-person noEdit :defaultDocument="item.PrsDocumentNumber" @returnPerson="returnPerson($event, item)" />
                    </v-col>

                    <v-col cols="7" class="s-col-form">
                        <s-text readonly label="Persona" v-model="item.NtpFullName">
                            <template v-slot:append-outer>
                                <v-icon color="error" style="margin-top:4px; cursor: pointer;" @click="
                      item.NtpFullName = null;
                      item.NtpID = null;
                    ">
                                    mdi-close-circle-outline
                                </v-icon>
                            </template>
                        </s-text>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col lg="5" cols="6" class="s-col-form">
                        <c-select-area full label="Area" v-model="item.AreID"></c-select-area>
                    </v-col>
                    <v-col lg="7" cols="6" class="s-col-form">
                        <c-select-position full label="Cargo" v-model="item.PstID"></c-select-position>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="3" cols="6">
                        <v-file-input :accept="'.png, .jpg'" label="Firma Digital" v-model="AttachImg" hide-details />
                        <span style="font-size:10px; color:red">Seleccione Firma</span>
                    </v-col>
                    <v-col lg="3" cols="6">
                        <v-file-input :accept="'.jpg'" label="Foto Trabajador (.jpg)" v-model="AttachImgWorker" hide-details />
                        <span style="font-size:10px; color:red">Seleccione Foto</span>
                    </v-col>
                    <v-col lg="3" cols="6">
                        <s-text ref="celular" label="Nro. Celular" v-model="item.Telephone"></s-text>
                        <span style="font-size:11px;">Ejem: 51970566794</span>
                    </v-col>
                    <v-col lg="3" cols="6">
                        <s-text ref="celular" label="Nro. WhatsApp" v-model="item.WrkWhatsApp"></s-text>
                        <span style="font-size:11px;">Ejem: 51970566794</span>
                    </v-col>
                    <v-col lg="3" cols="6">
                        <s-text ref="email" label="Email" v-model="item.Email"></s-text>
                    </v-col>
                    <v-col lg="3" cols="6">
                        <s-text ref="uri" label="Uri" v-model="item.WrkUri"></s-text>
                    </v-col>
                    <v-col lg="6" cols="6">
                        <s-text ref="uri" label="Video Presentacion" v-model="item.WrkVideo"></s-text>
                    </v-col>
                    <v-col lg="2" cols="6">
                        <v-checkbox style="margin-bottom: -30px; margin-top: 10px" v-model="item.AprrovedCircuit" label="Encargado Logistica" color="red" hide-details></v-checkbox>
                        <v-checkbox style="margin-bottom: -10px; margin-top: 20px" v-model="item.WrkJefature" label="Jefatura" color="red" hide-details></v-checkbox>
                    </v-col>
                    <!-- <v-col lg="2" cols="6">
                        <v-checkbox style="margin-bottom: -30px; margin-top: 10px" v-model="WrkJefature" label="Jefatura" color="red" hide-details></v-checkbox>
                    </v-col> -->
                </v-row>
            </v-container>
        </template>
        <template v-slot:AttachImg="{row}">
            <v-btn v-if="row.AttachImg !== null" color="success" class="mt-0" :disabled="false" icon x-small @click="openFile(AttachImg)">
                <v-icon>mdi-download</v-icon>
            </v-btn>
            {{row.AttachImg}}
        </template>
        <template v-slot:AprrovedCircuit="{ row }">
          <v-icon :color="row.AprrovedCircuit == true ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
        </template>
         <template v-slot:WrkJefature="{ row }">
          <v-icon :color="row.WrkJefature == true ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
        </template>
    </s-crud>

    <!-- <v-btn @click="WhatsAppCloudSend()"></v-btn> -->
</v-col>
</template>

<script>
import _sPositionPerson from "@/services/General/PositionPersonService.js";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

import _sWhatsAppSend from "@/services/General/WhatsAppService.js";
import _sGeneral from "@/services/HelperService.js";

export default {
    name: "",
    components: {
        sToolbarPerson
    },
    props: {},

    data: () => ({
        filter: {
            /* TypeArea: 0,
            AreID: 0 */
        },
        config: {
            model: {
                WrkID: "ID",
                AttachImg: "",
                AprrovedCircuit:"",
                WrkJefature:""
            },
            service: _sPositionPerson,
            headers: [{
                    text: "ID",
                    value: "WrkID",
                    sortable: false,
                    width: 10
                },
                {
                    text: "Persona",
                    value: "NtpFullName",
                    sortable: false
                },
                {
                    text: "Cargo",
                    value: "PstName",
                    sortable: false
                },
                {
                    text: "Area",
                    value: "AreName",
                    sortable: false
                },

                {
                    text: "Celular",
                    value: "Telephone",
                    sortable: false
                },

                {
                    text: "Email",
                    value: "Email",
                    sortable: false
                },

                {
                    text: "Firma Digital",
                    value: "AttachImg",
                    sortable: false
                },
                {
                    text: "Enc. Logistica",
                    value: "AprrovedCircuit",
                    sortable: false,
                    align: "center"
                },
                {
                    text: "Jefatura",
                    value: "WrkJefature",
                    sortable: false,
                    align: "center"
                },

                

            ],
        },

        item: {
        },
        AprrovedCircuit:false,
        WrkJefature:false,
        AttachImg: null,
        AttachImgWorker: null
    }),
    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 2).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        uploadFile() {
            let files = [];

            if (this.AttachImg) files.push(this.AttachImg);

            var formData = new FormData();
            let i = 0;
            files.forEach((element) => {
                formData.append(i.toString(), element);
                i++;
            });
            _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 4);
        },

        uploadFileWorker() {
            let files = [];

            if (this.AttachImgWorker) files.push(this.AttachImgWorker);

            var formData = new FormData();
            let i = 0;
            files.forEach((element) => {
                formData.append(i.toString(), element);
                i++;
            });
            _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 6);
        },

        rowSelected(item){
            this.AttachImg= null
            if(item.length > 0){           
                this.AprrovedCircuit = item[0].AprrovedCircuit
                console.log(item)
                if(item[0].AttachImg !== null)
                    this.AttachImg = {
                        name: item[0].AttachImg
                    };
            }
        },

        add(){
            this.AprrovedCircuit=false
        },

        returnPerson(value, item) {

            if (value !== null) {
                item.NtpFullName = value.NtpFullName;
                item.NtpID = value.NtpID;
            }

        },

        save(item) {

            if (item.NtpID == null) {
                this.$fun.alert("Ingrese Doc. Identidad", "warning")
                return
            }

            if (item.NtpFullName == "" || item.NtpFullName.length == 0) {
                this.$fun.alert("Ingrese Doc. Identidad", "warning")
                return
            }

            if (item.PstID == null) {
                this.$fun.alert("Seleccione Cargo", "warning")
                return
            }

            if (item.AreID == null) {
                this.$fun.alert("Seleccione Area", "warning")
                return
            }

            /* if (this.AttachImg == null) {
                this.$fun.alert("Seleccione Firma", "warning")
                return
            } */

            if (item.Telephone == null || item.Telephone == "" || item.Email.Telephone == 0) {
                this.$fun.alert("Ingrese Celular", "warning")
                this.$refs.celular.focus()
                return
            }

            if (item.Email == null || item.Email == "" || item.Email.length == 0) {
                this.$fun.alert("Ingrese Email", "warning")
                this.$refs.email.focus()
                return
            }

            if (!this.$fun.isValidEmail(item.Email)) {
                this.$refs.email.error("Email invalido")
                return
            }

            

            /* item.AprrovedCircuit = this.AprrovedCircuit
            item.WrkJefature = this.WrkJefature */
            item.SecStatus = 1
            item.UsrCreateID = this.$fun.getUserID();

            item.AttachImg =
                this.AttachImg != null ? this.AttachImg.name : "SinFirma.png";
            
            item.AttachImgWorker =
                this.AttachImgWorker != null ? this.AttachImgWorker.name : "SinFoto.png";

            if(item.AttachImgWorker.length > 12){
                this.$fun.alert("Nombre de Foto excede los 12 caracteres permitidos.", "warning")
                return
            }


            console.log("save",item)


            this.$fun.alert("¿Seguro de guardar ?", "question").then(val => {
                if (val.value) {
                    _sPositionPerson
                        .save(item, this.$fun.getUserID(), 0)
                        .then((r) => {
                            if (r.status == 200) {
                                this.uploadFile();
                                this.uploadFileWorker()
                                this.AttachImg= null
                                this.AttachImgWorker= null
                                this.$fun.alert("Guardado Correctamente", "success");
                                this.$refs.postionperson.refresh();
                            }
                        });
                }
            })
        },

        /* WhatsAppCloudSend(){
            _sWhatsAppSend.WhatsAppCloudSend(42).then(resp => {
                console.log("holaaaaaaaaaa",resp.data)
            })
        } */
    },
};
</script>
